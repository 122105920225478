import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Dashboard';

export default defineMessages({
  copiedToClipboard: {
    id: `${scope}.copied_to_clipboard`,
    defaultMessage: 'Copied to clipboard'
  },
  viewPayments: {
    id: `${scope}.view_payments`,
    defaultMessage: 'View Payments'
  },
  averageRating: {
    id: `${scope}.average_rating`,
    defaultMessage: 'Average Rating'
  },
  recentPayments: {
    id: `${scope}.recent_payments`,
    defaultMessage: 'Recent Payments'
  },
  lastSevenDays: {
    id: `${scope}.last_seven_days`,
    defaultMessage: 'Last 7 days'
  },
  totalTips: {
    id: `${scope}.total_tips`,
    defaultMessage: 'Total tips'
  },
  totalAllTime: {
    id: `${scope}.total_all_time`,
    defaultMessage: 'Total[All Time]'
  },
  unclaimedTips: {
    id: `${scope}.unclaimedTips`,
    defaultMessage:
      'You have {unremittedAmount} in unclaimed tips. Team tips are not paid out immediately so make sure to transfer them when you want the money moved to your preferred payout method.'
  },
  remitSuccessful: {
    id: `${scope}.remitSuccessful`,
    defaultMessage:
      'Successful! Your balance will be transferred via your preferred payment method shortly'
  },
  transferButton: {
    id: `${scope}.transferButton`,
    defaultMessage: 'Transfer Tips'
  },
  completeKYC: {
    id: `${scope}.completeKYC`,
    defaultMessage: 'Complete KYC'
  }
});
