/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { StyleBreakpoints } from '@constants';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  background: {
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    zIndex: '1250',
    backgroundColor: 'rgba(0,0,0,0.7)',
    display: 'flex',
    alignItems: 'flex-end',
    padding: '32px',
    justifyContent: 'center'
  },
  container: {
    background: 'white',
    zIndex: '1300',
    flex: 1,
    height: '246px',
    minHeight: '246px',
    display: 'flex',
    alignItems: 'center',
    padding: '24px 15px 5px 15px',
    flexDirection: 'column',
    borderRadius: '16px',

    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      maxWidth: '400px',
      margin: 'auto'
    }
  },
  description: {
    marginTop: '16px',
    fontSize: '12px',
    color: '#000',
    textAlign: 'center',
    flex: 1
  },
  icon: {
    background: 'white',
    display: 'flex',
    width: '58px',
    height: '58px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.29)'
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  }
}));

const MobileConfirmationDialog = ({ icon, isShown, description, close, buttons }) => {
  const classes = useStyles();

  return (
    <>
      {isShown && (
        <div className={classes.background} onClick={close}>
          <div
            className={classes.container}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}>
            <div className={classes.icon}>{icon}</div>
            <div className={classes.description}>{description}</div>
            <div className={classes.bottomContainer}>
              {buttons.map(button => {
                return button;
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileConfirmationDialog;
