import { Analytics } from '@analytics';
import { removeFromTeam } from '@api/taptotip/group';
import ConfirmIcon from '@assets/images/ConfirmIcon.svg';
import { GroupEntityType } from '@constants';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useInjectSaga } from '@utils/injectSaga';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import * as actions from '../../../../containers/TippingGroupPage/actions';
import saga from '../../../../containers/TippingGroupPage/saga';
import GroupDetailsCard from '../GroupDetailsCard';
import messages from './messages';

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  groupActionButtons: {
    marginTop: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  avatarIcon: {
    background: 'white',
    borderRadius: '50%',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.29)'
  },
  button: {
    width: '100%',
    maxWidth: '354px',
    height: '39px',
    backgroundColor: '#6997ff',
    color: 'white',
    marginTop: '24px',

    '&:first-of-type': {
      marginTop: '12px'
    },

    '&:hover': {
      backgroundColor: '#6982ff'
    },

    '&:disabled': {
      backgroundColor: '#dadbdb',
      color: '#fff',

      '&:hover': {
        backgroundColor: '#ccc'
      }
    }
  },
  cancelButton: {
    marginTop: '8px',
    color: '#a0a0a0'
  },
  claimButton: {
    backgroundColor: '#018101',
    color: '#d8fdd8',

    '&:hover': {
      backgroundColor: '#339a33'
    }
  },
  removeButton: {
    backgroundColor: '#fe424d',
    color: '#ffffff'
  },
  leaveButton: {
    backgroundColor: '#e0e3e0',
    color: '#a8a8a8',

    '&:hover': {
      backgroundColor: '#a0a0a0',
      color: '#fff'
    }
  }
}));

const GroupDetails = ({
  group,
  user,
  resource,
  cardOwnerElement,
  cardOwnerType,
  isUserInGroup,
  setDescription,
  setButtons,
  setDialogIcon,
  setIsConfirmationDialogShown,
  closeConfirmationDialog,
  fullSize = false,
  setCardView,
  addToGroup
}) => {
  const classes = useStyles();
  const intl = useIntl();
  useInjectSaga({ key: 'tipPayments', saga });

  useEffect(() => {
    Analytics.trackViewFeature('Teams');
  }, []);

  const showRemoveFromTeamDialog = ({ groupId, type, id, description, icon }) => {
    setDialogIcon(icon);
    setDescription(description);

    setButtons([
      <Button
        key="remove-button"
        className={classes.removeButton}
        onClick={() => {
          Analytics.track('remove from group', {
            type
          });
          removeFromTeam(groupId, type, id);
        }}>
        {intl.formatMessage(messages.removeFromTeam)}
      </Button>,
      <Button
        className={classes.cancelButton}
        color="secondary"
        key="cancel-button"
        onClick={closeConfirmationDialog}>
        {intl.formatMessage(messages.cancel)}
      </Button>
    ]);

    setIsConfirmationDialogShown(true);
  };

  const showLeaveTeamDialog = ({ groupId, userId, icon }) => {
    setDialogIcon(icon);
    setDescription(intl.formatMessage(messages.leaveTeamConfirmation));

    setButtons([
      <Button
        key="remove-button"
        className={`${classes.removeButton} ${classes.leaveButton}`}
        onClick={() => {
          Analytics.track('leave group');
          removeFromTeam(groupId, 'user', userId);
        }}>
        {intl.formatMessage(messages.leaveTeam)}
      </Button>,
      <Button
        className={classes.cancelButton}
        color="secondary"
        key="cancel-button"
        onClick={closeConfirmationDialog}>
        {intl.formatMessage(messages.cancel)}
      </Button>
    ]);

    setIsConfirmationDialogShown(true);
  };

  const showClaimResourceDialog = ({ resourceId }) => {
    setDialogIcon(<img alt="confirm" src={ConfirmIcon} />);
    setDescription(intl.formatMessage(messages.claimResourceConfirmation));

    setButtons([
      <Button
        className={classes.claimButton}
        key="confirm-button"
        onClick={() => {
          Analytics.trackUseFeature('Teams');
          Analytics.track('add to team', {
            type: GroupEntityType.resource
          });
          addToGroup({
            type: GroupEntityType.resource,
            id: resourceId
          });
        }}>
        {intl.formatMessage(messages.claimResource)}
      </Button>,
      <Button
        className={classes.cancelButton}
        color="secondary"
        key="cancel-button"
        onClick={closeConfirmationDialog}>
        {intl.formatMessage(messages.cancel)}
      </Button>
    ]);

    setIsConfirmationDialogShown(true);
  };

  const renderActionButtons = () => {
    const buttons = [];

    if (!group) {
      buttons.push(
        <Button
          key="add-member-button"
          className={classes.button}
          onClick={() => {
            Analytics.trackUseFeature('Teams');
            Analytics.track('add to team');
            return addToGroup({
              type: GroupEntityType.user,
              id: cardOwnerElement._id
            });
          }}>
          {intl.formatMessage(messages.addToGroup)}
        </Button>);
    }

    if (group && !isUserInGroup) {
      buttons.push(
        <Button
          key="join-button"
          className={classes.button}
          onClick={() => {
            Analytics.trackUseFeature('Teams');
            Analytics.track('join group');
            return addToGroup({
              groupId: group._id,
              type: GroupEntityType.user,
              id: user.userId
            });
          }}>
          {intl.formatMessage(messages.joinTeam)}
        </Button>
      );
    }

    if (!isUserInGroup && cardOwnerType === GroupEntityType.resource) {
      buttons.push(
        <Button
          key="claim-button"
          className={classes.button}
          onClick={() => {
            Analytics.trackUsedFeature('Teams');
            Analytics.track('claim resource', {
              type: GroupEntityType.resource
            });
            showClaimResourceDialog({
              resourceId: cardOwnerElement._id
            });
          }}>
          {intl.formatMessage(messages.claimResource)}
        </Button>
      );
    }

    if (isUserInGroup) {
      buttons.push(
        <Button
          key="leave-button"
          className={`${classes.button} ${classes.leaveButton}`}
          onClick={() =>
            showLeaveTeamDialog({
              icon: (
                <img
                  className={`${classes.icon} ${classes.avatarIcon}`}
                  src={user.profile.avatarUrl}
                  alt="users avatar"
                />
              ),
              groupId: group._id,
              userId: user.userId
            })
          }>
          {intl.formatMessage(messages.leaveTeam)}
        </Button>
      );
    }

    return buttons;
  };

  return (
    <>
      <div className={classes.content}>
        <div className={classes.body}>
          <div className={classes.groupMembers}>
            <GroupDetailsCard
              isUserInGroup={isUserInGroup}
              group={group}
              type={GroupEntityType.user}
              user={user}
              elements={group.users}
              showRemoveFromTeamDialog={showRemoveFromTeamDialog}
              fullSize={fullSize}
              setCardView={setCardView}
            />
          </div>
          <div className={classes.groupResources}>
            <GroupDetailsCard
              isUserInGroup={isUserInGroup}
              group={group}
              type={GroupEntityType.resource}
              elements={group.resources}
              showRemoveFromTeamDialog={showRemoveFromTeamDialog}
              fullSize={fullSize}
            />
          </div>
          <div className={classes.groupActionButtons}>{renderActionButtons()}</div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = dispatch => ({
  addToGroup: info => dispatch(actions.addToGroup(info))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupDetails);
