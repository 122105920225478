import { defineMessages } from 'react-intl';

export const scope = 'app.components.Onboarding';

export default defineMessages({
  welcomeDescription: {
    id: `${scope}.welcomeDescription`,
    defaultMessage:
      'Let us help you get setup. To get started, enter some profile information about yourself below. '
  },
  welcomeBoldText: {
    id: `${scope}.welcomeBoldText`,
    defaultMessage: 'Welcome'
  },
  additionalInfoDescription: {
    id: `${scope}.additionalInfoDescription`,
    defaultMessage:
      'Now we just need to know how much you’d like to get paid, in what currency and what country you are in.'
  },
  additionalInfoBoldText: {
    id: `${scope}.additionalInfoBoldText`,
    defaultMessage: 'Additional Info'
  },
  nearlyThereDescription: {
    id: `${scope}.nearlyThereDescription`,
    defaultMessage:
      'Now we just need you to tell us how to pay you, select on of our supported payment methods below'
  },
  nearlyThereBoldText: {
    id: `${scope}.nearlyThereBoldText`,
    defaultMessage: 'Nearly There!'
  },
  congratulationsDescription: {
    id: `${scope}.congratulationsDescription`,
    defaultMessage:
      'You’re all setup and ready to start taking digital tips. Explore addtional Tip Direct features below'
  },
  congratulationsBoldText: {
    id: `${scope}.congratulationsBoldText`,
    defaultMessage: 'Congratulations'
  },
  personalTippingDescription: {
    id: `${scope}.personalTippingDescription`,
    defaultMessage:
      'Share your unique link with customers via text or email to provide other options for them to leave tips.'
  },
  personalTippingBoldText: {
    id: `${scope}.personalTippingBoldText`,
    defaultMessage: 'Personal Tipping Link'
  },
  inviteGuidesDescription: {
    id: `${scope}.inviteGuidesDescription`,
    defaultMessage: 'Would you like to invite guides to start using Tip Direct? you can do so here.'
  },
  inviteGuidesBoldText: {
    id: `${scope}.inviteGuidesBoldText`,
    defaultMessage: 'Invite Guides'
  },
  setupReviewsDescription: {
    id: `${scope}.setupReviewsDescription`,
    defaultMessage:
      'We make it easy for you to receive reviews on tours, make sure yours are linked.'
  },
  setupReviewsBoldText: {
    id: `${scope}.setupReviewsBoldText`,
    defaultMessage: 'Setup Reviews'
  },
  orderCardsDescription: {
    id: `${scope}.orderCardsDescription`,
    defaultMessage: 'Need Tip Direct cards? Order them through our website.'
  },
  orderCardsBoldText: {
    id: `${scope}.orderCardsBoldText`,
    defaultMessage: 'Order Cards'
  },
  getPaidBoldText: {
    id: `${scope}.getPaidBoldText`,
    defaultMessage: 'How do I get paid?'
  },
  getPaidDescription: {
    id: `${scope}.getPaidDescription`,
    defaultMessage:
      'Once you receive a tip we will send you an email with a link to claim it. Once you choose your payout method the rest of your tips will be sent automatically.'
  },
  noTipsYetBoldText: {
    id: `${scope}.noTipsYetBoldText`,
    defaultMessage: 'No Tips Yet!'
  },
  noTipsYetDescription: {
    id: `${scope}.noTipsYetDescription`,
    defaultMessage:
      'Your dashboard will display a breakdown of all tips you earn. Once you get your first tip, we will ask you for your payout details.'
  },
  previewTippingFlowBoldText: {
    id: `${scope}.previewTippingFlowBoldText`,
    defaultMessage: 'Preview Tipping Flow'
  },
  previewTippingFlowDescription: {
    id: `${scope}.previewTippingFlowDescription`,
    defaultMessage:
      'Do you want to see what your tipping flow looks like for customers? Preview it here.'
  },
  personalQRBoldText: {
    id: `${scope}.personalQRBoldText`,
    defaultMessage: 'Personal QR Code'
  },
  personalQRDescription: {
    id: `${scope}.personalQRDescription`,
    defaultMessage:
      'Using your QR code you can still accept digital tips if you forget or lose your TipDirect card.'
  },
  printOutBoldText: {
    id: `${scope}.printOutBoldText`,
    defaultMessage: 'Print Out'
  },
  printOutDescription: {
    id: `${scope}.printOutDescription`,
    defaultMessage:
      'Download a printable .pdf of your tipping profile. Handy if you are dealing with big crowds.'
  },
  guideGalleryBoldText: {
    id: `${scope}.guideGalleryBoldText`,
    defaultMessage: 'Guide Gallery'
  },
  guideGalleryDescription: {
    id: `${scope}.guideGalleryDescription`,
    defaultMessage: 'Collect tips for all of the guides in your organisation with this link.'
  },
  companyInviteLinkBoldText: {
    id: `${scope}.companyInviteLinkBoldText`,
    defaultMessage: 'Company Invite Link'
  },
  companyInviteLinkDescription: {
    id: `${scope}.companyInviteLinkDescription`,
    defaultMessage: 'Invite anyone to your company with this sharable link.'
  },
  paymentLinkQRBoldText: {
    id: `${scope}.paymentLinkQRBoldText`,
    defaultMessage: 'QR Link'
  },
  paymentLinkQRDescription: {
    id: `${scope}.paymentLinkQRDescription`,
    defaultMessage: 'Visitors can scan this with their phones to pay the amount.'
  }
});
