import { call, put, takeLatest } from '@redux-saga/core/effects';
import { addToGroup } from '../../api/taptotip/group';
import { extractStatusBarError, snackbarStatus } from '../StatusBar';
import { setSnackbarStatus } from '../StatusBar/actions';
import * as types from './types';

function* addToGroupCall({ payload }) {
  try {
    yield call(addToGroup, payload);
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

export default function* tapToTipWatcher() {
  yield takeLatest(types.ADD_TO_GROUP, addToGroupCall);
}
